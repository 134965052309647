import type { NextPage } from "next";
import {
  ConnectingYou,
  ConvertMultiple,
  DigitalBank,
  LandingLayout,
  PersonalBanner,
  SendAndReceiveAnywhere,
  ShopGlobalMarkets,
  WhyQwid,
  useToolkit,
} from "../src/components";
import CookieConsent from "react-cookie-consent";

const Home: NextPage = () => {
  const { isMobile, Link } = useToolkit();

  return (
    <LandingLayout
      bgPath={
        isMobile
          ? 'url("/assets/landing/lp-banner-mobile.svg")'
          : 'url("/assets/landing/lp-banner.svg")'
      }
      BannerChildren={<PersonalBanner />}
    >
      <ConnectingYou />
      <SendAndReceiveAnywhere />
      <ConvertMultiple />
      <ShopGlobalMarkets />
      <WhyQwid />
      <DigitalBank />
      <CookieConsent
        location="bottom"
        buttonText="Understood"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance your experience and provide
        personalized information.{" "}
        <Link href="/privacy-policy">
          <span className="text-xs pry-text underline curp">Read more</span>
        </Link>
      </CookieConsent>
    </LandingLayout>
  );
};

export default Home;
